@use 'variables';

:root {
  --main-color: #11a00d;
  --color-1: #e91e63;
  --color-2: #f5ae10;
  --color-3: #09d69c;
  --bg-dark: #2b2c2f;
  --main-to-dark-color: var(--main-color);
  --dark-to-main-color: var(--bg-dark);
  --shadow-black-100: 0 5px 15px rgba(0, 0, 0, 0.1);
  --shadow-black-300: 0 5px 15px rgba(0, 0, 0, 0.3);
  --black-900: #000000;
  --black-400: #555555;
  --black-300: #bfbfbf;
  --black-100: #f7f7f7;
  --black-000: #ffffff;
  --black-alpha-100: rgba(0, 0, 0, 0.05);
}

body {
  margin: 0;
  font-size: 16px;
  font-family: 'Poppins', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  line-height: 1.5;
  overflow-x: hidden;
}

body.dark {
  background-color: var(--bg-dark);
  --black-100: var(--bg-dark);
  --black-000: var(--bg-dark);
  --main-to-dark-color: var(--bg-dark);
  --dark-to-main-color: var(--main-color);
  --shadow-black-100: var(var(--shadow-black-300));
  --black-alpha-100: rgba(255, 255, 255, 0.05);
  --black-900: #ffffff;
  --black-400: #bbbbbb;
}

* {
  margin: 0;
  padding: 0;
  outline: none !important;
}

input:focus,
textarea:focus,
select:focus {
  outline: none !important;
}

html {
  scroll-behavior: smooth;
}

img {
  max-width: 100%;
}

ul {
  list-style: none;
}

.dark {
  .slide-pane {
    background-color: #202633;
  }
}
