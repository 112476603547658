@use 'globals';
@use 'hero';
@use 'variables';

// Effects

.effect-wrap {
  .effect {
    position: absolute;
    z-index: -1;
  }

  .effect-1 {
    left: 20%;
    top: 16%;
    font-size: 20px;
    color: var(--color-1);
    animation: spin_01 5s linear infinite;
  }

  .effect-2 {
    right: 5%;
    top: 10%;
    font-size: 25px;
    color: rgba(255, 255, 255, 0.5);
    animation: spin_01 7s linear infinite;
  }

  .effect-3 {
    left: 5%;
    bottom: 30%;
    font-size: 25px;
    color: var(--color-3);
    animation: bounceTop_01 3s linear infinite;
  }
}

.circle-ripple {
  background-color: #ff3030;
  width: 50px;
  height: 50px;
  border-radius: 25px;
  animation: ripple 1.7s linear infinite;
  float: right;
  margin-top: -227px;
  margin-left: 361px;
  position: absolute;
  scale: 0.5;
  opacity: 0.7;
}

// Toggle Theme

.toggle-theme {
  position: fixed;
  right: 0;
  top: calc(50% - 20px);
  height: 40px;
  width: 40px;
  background-color: var(--dark-to-main-color);
  z-index: 1200;
  color: #ffffff;
  font-size: 16px;
  text-align: center;
  border-radius: 50%;
  box-shadow: var(--shadow-black-300);
  cursor: pointer;

  .fontAwesome {
    line-height: 40px;
  }
}

// Checkmark Selector

.check-icon {
  height: 40px;
  width: 40px;
  color: var(--dark-to-main-color);
  font-size: 16px;
  text-align: center;
  cursor: pointer;

  .fontAwesome {
    line-height: 40px;
  }
}

// Sliding Panel

.sliding-panel {
  z-index: 9999;
}

// Payment Method

.payment-method-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border: 1px solid hsl(0, 0%, 80%);
  background-color: #ffffff;
  border-radius: 5px;
  padding: 0 10px;
  color: #000000;
  transition: all 0.5s ease;
  margin-bottom: 5px;
  width: 100%;
  cursor: pointer;
  height: 40px;

  &:focus {
    outline: none !important;
  }

  .details {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .logo {
    width: 30px;
    height: 30px;
  }

  .description {
    font-size: 14px;
    padding-left: 10px;
    margin-bottom: 0px;
    color: #000000;
  }
}

// Estimeted price

.estimated-title {
  margin-top: 10px;
  margin-bottom: 10px;

  p {
    font-size: 18px;
    color: var(--black-300);
    font-weight: 400;
    text-transform: capitalize;
    margin: 0;
    line-height: 28px;
  }
}

// Section

.section-padding {
  padding: 80px 0;
}

.section-padding-top-10rem {
  padding-top: 5rem;
}

.section-padding-left-10rem {
  padding-left: 5rem;
}

.section-title {
  margin-bottom: 60px;

  h2 {
    font-size: 54px;
    color: var(--black-900);
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    line-height: 52px;

    span {
      color: var(--main-color);
    }
  }

  h4 {
    font-size: 18px;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    line-height: 52px;
  }

  p {
    font-size: 30px;
    color: var(--black-300);
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
  }
}

.section-title-header {
  margin-bottom: 60px;

  h2 {
    font-size: 54px;
    color: #ffffff;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
    line-height: 52px;

    span {
      color: var(--main-color);
    }
  }

  p {
    font-size: 30px;
    color: #ffffff;
    font-weight: 700;
    text-transform: capitalize;
    margin: 0;
  }
}

.section-description {
  font-size: 18px;
  font-weight: 400;
  color: var(--black-900);
}

// Animations

@keyframes ripple {
  0% {
    box-shadow: 0 0 0 0 rgba(variables.$red, 0.3),
      0 0 0 1em rgba(variables.$red, 0.3), 0 0 0 3em rgba(variables.$red, 0.3),
      0 0 0 5em rgba(variables.$red, 0.3);
  }

  100% {
    box-shadow: 0 0 0 1em rgba(variables.$red, 0.3),
      0 0 0 3em rgba(variables.$red, 0.3), 0 0 0 5em rgba(variables.$red, 0.3),
      0 0 0 8em rgba(variables.$red, 0);
  }
}

@keyframes spin_01 {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

@keyframes bounceTop_01 {
  0%,
  100% {
    transform: translateY(20px);
  }

  50% {
    transform: translateY(0px);
  }
}

@keyframes pulse_01 {
  0% {
    transform: scale(0.94);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.7);
  }

  70% {
    transform: scale(1);
    box-shadow: 0 0 0 12px rgba(255, 255, 255, 0);
  }

  100% {
    transform: scale(0.94);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0);
  }
}

// Navbar

#collapsibleNavbar {
  justify-content: flex-end;
}

.navbar {
  background-color: var(--main-color);
  padding: 20px 0;
  transition: all 0.5s ease;

  .container {
    padding: 0 15px;
  }

  .navbar-brand {
    cursor: pointer;
    img {
      height: 35px;
    }
  }

  .nav-item {
    padding-left: 40px;

    .nav-link {
      color: variables.$white;
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      padding: 5px 0;
      position: relative;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background-color: variables.$white;
        transition: all 0.5s ease;
        transform: scale(0);
      }

      &:hover:before {
        transform: scale(1);
      }
    }

    .nav-link.active {
      color: white;
      &:before {
        transform: scale(1);
      }
    }
  }
}

.navbar.navbar-shrink {
  box-shadow: 0 10px 10px rgba(0, 0, 0, 0.1);
  background-color: var(--main-color);
  padding: 10px 15px;
}



// Map Details

.map-details {
  box-shadow: 0 5px 15px rgb(0 0 0 / 10%);
  position: absolute;
  background-color: #ffffff;
  z-index: 1;
}

.track-sliding-panel {
  .slide-pane {
    .slide-pane__header {
      background-color: #ffffff;
    }

    .slide-pane__content {
      padding: 0;
    }
  }
}

// Buttons

.home-btn {
  margin-top: 0px;
  display: flex;
  align-items: center;

  .btn-1 {
    background-color: #ffffff;
    padding: 12px 30px;
    border: none;
    border-radius: 10px;
    color: var(--main-color);
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.5s ease;
    box-shadow: var(--shadow-black-300);
    font-weight: 500;

    &:focus {
      box-shadow: var(--shadow-black-300);
    }

    &:hover {
      color: #ffffff;
      background-color: var(--main-color);
    }
  }

  .video-play-btn {
    margin-left: 30px;
    width: 50px;
    height: 50px;
    padding: 0;
    animation: pulse_01 2s infinite;

    &:hover {
      animation: none;
      box-shadow: var(--shadow-black-300);
    }
  }

  .store-buttons {
    margin-left: 30px;

    .store-btn {
      margin-left: 10px;
      padding: 12px 12px;
      border: none;
      border-radius: 10px;
      color: var(--main-color);
      font-size: 40px;
      text-transform: capitalize;
      transition: all 0.5s ease;
      font-weight: 500;
      color: #ffffff;
    }
  }

  .schedule-btn {
    margin-left: 30px;
    height: 50px;
    padding: 0;
    animation: pulse_01 2s infinite;

    &:hover {
      animation: none;
      box-shadow: var(--shadow-black-300);
    }

    p {
      margin-bottom: 0px;
      margin-left: 10px;
    }
  }
}

.cancel-btn {
  margin-left: 20px;
}

.login-btn {
  margin-top: 40px;

  .btn-3 {
    background-color: #ffffff;
    padding: 12px 30px;
    margin-left: 20px;
    border: none;
    border-radius: 10px;
    color: var(--main-color);
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.5s ease;
    box-shadow: var(--shadow-black-300);
    font-weight: 500;

    &:focus {
      box-shadow: var(--shadow-black-300);
    }

    &:hover {
      color: #ffffff;
      background-color: var(--main-color);
    }
  }

  .btn-4 {
    background-color: var(--main-color);
    padding: 12px 30px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.5s ease;
    box-shadow: var(--shadow-black-300);
    font-weight: 500;

    &:focus {
      box-shadow: var(--shadow-black-300);
    }

    &:hover {
      color: #ffffff;
      background-color: var(--main-to-dark-color);
    }
  }
}

.already-customer-btn {
  margin-top: 20px;

  a {
    color: #ffffff;
    text-transform: capitalize;
    transition: all 0.5s ease;
    text-decoration: none;
    cursor: pointer;

    &:hover {
      padding-left: 5px;
      color: #ffffff;
    }
  }
}

.form-group {
  .btn-2 {
    background-color: var(--main-color);
    padding: 12px 30px;
    border: none;
    border-radius: 10px;
    color: #ffffff;
    font-size: 16px;
    text-transform: capitalize;
    transition: all 0.5s ease;
    box-shadow: var(--shadow-black-300);
    font-weight: 500;

    &:focus {
      box-shadow: var(--shadow-black-300);
    }

    &:hover {
      color: #000000;
      background-color: #ffffff;
    }
  }

  .dropdown {
    width: 100%;
    border: 0px;
    background: #ffffff;
    text-transform: capitalize;
    text-align: left;

    &:hover {
      color: var(--black-300);
      background-color: #ffffff;
    }
  }
}

.owl-carousel {
  .owl-dots {
    padding: 0 15px;
    text-align: center;
  }

  button.owl-dot {
    height: 6px;
    width: 24px;
    background-color: #dddddd !important;
    display: inline-block;
    margin: 0 4px;
    border-radius: 5px;
  }

  button.owl-dot.active {
    background-color: var(--main-color) !important;
  }
}

.sms-request-form {
  margin: 0 auto;
  justify-content: center;
  max-width: 420px;

  .form-group {
    margin-top: 20px;
    margin-bottom: 5px;
  }

  .form-control {
    height: 52px;
    border: 1px solid transparent;
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    padding: 0 24px;
    color: #000000;
    transition: all 0.5s ease;

    &:focus {
      outline: none !important;
    }
  }

  textarea.form-control {
    height: 140px;
    padding-top: 12px;
    resize: none;
  }

  .btn-2 {
    margin: 40px 0;
    background-color: var(--main-to-dark-color);
  }
}

// Order Request Form

.order-request {
  border-radius: 10px;
  padding: 20px 0;

  .order-request-text {
    h3 {
      color: #ffffff;
      line-height: 45px;
      font-weight: 700;
    }
  }
}

.order-request-form {
  .form-group {
    margin-bottom: 5px;
  }

  .form-control {
    height: 52px;
    border: 1px solid transparent;
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    padding: 0 24px;
    color: #000000;
    transition: all 0.5s ease;

    &:focus {
      outline: none !important;
    }
  }

  textarea.form-control {
    height: 140px;
    padding-top: 12px;
    resize: none;
  }

  .btn-2 {
    margin: 40px 0;
    background-color: var(--main-to-dark-color);
  }
}

.topup-request-form {
  padding-bottom: 30px;

  .form-group {
    margin-bottom: 5px;
  }

  .form-control {
    height: 52px;
    border: 1px solid transparent;
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    padding: 0 24px;
    color: #000000;
    transition: all 0.5s ease;

    &:focus {
      outline: none !important;
    }
  }

  textarea.form-control {
    height: 140px;
    padding-top: 12px;
    resize: none;
  }

  .btn-2 {
    margin: 40px 0;
    background-color: var(--main-to-dark-color);
  }
}

// Driver Container

.driver-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 40px;

  .driver-card {
    display: flex;
    align-items: center;
    justify-content: space-between;

    img {
      width: 50px;
      height: 50px;
      object-fit: cover;
      border-radius: 50%;
      box-shadow: var(--shadow-black-100);
    }

    .driver-details {
      padding-left: 10px;

      h4 {
        line-height: 16px;
        margin: 0px;
      }

      p {
        font-size: 16px;
        color: var(---black-100);
        margin-bottom: 0px;
      }
    }
  }

  .driver-car {
    text-align: right;

    h4 {
      line-height: 16px;
      margin: 0px;
    }

    p {
      font-size: 16px;
      color: var(---black-100);
      margin-bottom: 0px;
    }
  }
}

.disclaimer {
  margin-top: 1rem;
  font-size: 14px;
}

// Search Results Component

.search-results-container {
  position: absolute;
  border: 1px solid #ffffff;
  background-color: #ffffff;
  box-shadow: var(--shadow-black-100);
  padding: 10px;
  width: calc(100% - 30px);
  border-radius: 5px;
  z-index: 2;
  max-width: 500px;
}

.search-result {
  color: var(---black-900);
  padding: 5px;
  cursor: pointer;
}

// Services Section
.services {
  min-height: 100vh;
  padding: 150px 0;
  background-color: var(--main-to-dark-color);
  background-image: url('/images/background_services.jpg');
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

// Services Icons
.services-vert {
  background-image: url('/images/standard.png');
  height: 230px;
  width: 100%;
  background-size: 230px;
  background-repeat: no-repeat;
  background-position: center;
}

.services-vert-prime {
  background-image: url('/images/vert_exec.png');
  height: 230px;
  width: 100%;
  background-size: 230px;
  background-repeat: no-repeat;
  background-position: center;
}

.services-vert-elite {
  background-image: url('/images/vert_plus.png');
  height: 230px;
  width: 100%;
  background-size: 230px;
  background-repeat: no-repeat;
  background-position: center;
}

.services-description {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 17px;
  margin: 0 0 20px;
  color: var(--black-900);
  font-weight: 500;
  text-transform: capitalize;
}

// Driver Section
.driver {
  min-height: 100vh;
  padding: 150px 0;
  background-color: var(--main-to-dark-color);
  background-image: url('/public/images/background_driver.jpg');
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.driver-text {
  h1 {
    font-size: 45px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 0 20px;
    background-color: var(--main-to-dark-color);
    padding: 5px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    margin: 0;
    font-weight: 400;
    background-color: var(--main-to-dark-color);
    padding: 5px;
  }
}

// Home Section

.home {
  min-height: 100vh;
  padding: 150px 0;
  background-color: var(--main-to-dark-color);
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.home-text {
  h1 {
    font-size: 45px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 0 20px;
  }

  p {
    font-size: 18px;
    line-height: 28px;
    color: #ffffff;
    margin: 0;
    font-weight: 400;
  }
}

.home-img {
  position: relative;
  width: 500px;
  height: 500px;

  .circle {
    animation: spin_01 20s linear infinite;
    left: 25px;

    img {
      transform: rotate(-40deg);
      padding-top: 35px
    }
  }

  .home-background {
    opacity: 0.9;
    position: absolute;
    top: 20px;
    left: 0px;
    z-index: -1;
    height: 380px;
    width: 445px;
  }

  .phone-hero {
    animation: bounceTop_01 3s ease infinite;
    left: 25px;
  }
}

.order-price {
  .estimation-disclaimer {
    font-size: 10px;
    color: var(--black-900);
  }
}

// Schedule for later

.schedule-container {
  display: flex;
  background-color: #ffffff;
  border-radius: 10px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.schedule-title {
  font-weight: bold;
  font-size: 28px;
}

.schedule-time {
  font-weight: bold;
  font-size: 20px;
}

.container-radio {
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }

  ul li {
    color: black;
    display: block;
    position: relative;
    float: left;
    width: 100%;
    cursor: pointer;
    border-bottom: 0.5px solid #f2f2f2;
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
  }

  ul li input[type='radio'] {
    position: absolute;
    visibility: hidden;
  }

  ul li label {
    display: block;
    position: relative;
    font-weight: 300;
    font-size: 18px;
    margin: 14px 45px;
    height: 30px;
    z-index: 9;
    cursor: pointer;
    -webkit-transition: all 0.25s linear;
  }

  ul li:hover label {
    color: #aaaaaa;
  }

  ul li .check {
    display: block;
    position: absolute;
    border: 5px solid #aaaaaa;
    border-radius: 100%;
    height: 30px;
    width: 30px;
    top: 15px;
    z-index: 5;
    transition: border 0.25s linear;
    -webkit-transition: border 0.25s linear;
  }

  ul li:hover .check {
    border: 5px solid #aaaaaa;
  }

  ul li .check::before {
    display: block;
    position: absolute;
    content: '';
    border-radius: 100%;
    height: 10px;
    width: 10px;
    top: 5px;
    left: 5px;
    margin: auto;
    transition: black 0.25s linear;
    -webkit-transition: black 0.25s linear;
  }
}

input[type='radio'].selected-radio ~ .check {
  border: 5px solid #000000;
}

input[type='radio'].selected-radio ~ .check::before {
  background: #000000;
}

input[type='radio'].selected-radio ~ label {
  color: #000000;
}

// Video Pop-Up

.video-popup {
  padding: 30px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5 ease;

  .video-popup-inner {
    width: 100%;
    max-width: 900px;
    position: relative;
  }

  .video-popup-close {
    position: absolute;
    right: 0;
    top: -30px;
    height: 30px;
    width: 30px;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #ffffff;
  }

  .iframe-box {
    height: 0;
    overflow: hidden;
    padding-top: 56.25%;
  }

  #player-1 {
    position: absolute;
    border-radius: 20px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
  }
}

.open-video {
  opacity: 1;
  visibility: visible;
}

// Alert Modal

.alert-popup {
  padding: 30px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5 ease;

  .alert-inner {
    width: 100%;
    padding-bottom: 20px;
    max-width: 700px;
    position: relative;
    background-color: var(--black-100);
    box-shadow: var(--shadow-black-200);
  }
}

.section-padding-alert {
  padding: 40px 40px;
}

.section-title-alert {
  h2 {
    font-size: 24px;
    line-height: 30px;
  }

  p {
    font-size: 20px;
  }
}

.open-alert {
  opacity: 1;
  visibility: visible;
}

// Tariff Pop-Up

.tariff-popup {
  padding: 30px;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  z-index: 1999;
  background-color: rgba(0, 0, 0, 0.7);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5 ease;

  .tariff-popup-inner {
    width: 100%;
    max-width: 900px;
    position: relative;
  }

  .tariff-popup-close {
    position: absolute;
    right: 0;
    top: -30px;
    height: 30px;
    width: 30px;
    font-size: 16px;
    text-align: center;
    line-height: 30px;
    cursor: pointer;
    color: #ffffff;
  }

  .tariff-container {
    border-radius: 20px;
    overflow: hidden;
    background-color: var(--black-100);
    box-shadow: var(--shadow-black-200);
  }

  .header-container {
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 20px;
    align-items: flex-end;

    .header-title {
      margin: 0;
      color: var(--black-900);
    }
  }

  .tariff-wrapper {
    padding-top: 20px;
    border-bottom: 1px solid var(--main-color);
    padding-bottom: 20px;

    .tariffs-container {
      display: flex;
      justify-content: space-between;

      p {
        font-size: 0.9rem;
        width: 150px;
        color: var(--black-900);
        padding-top: 1rem;
      }
    }
  }

  .estimation-wrapper {
    padding-top: 20px;
    padding-bottom: 20px;

    .estimation-content {
      p {
        margin: 0;
        font-weight: '500';
        color: var(--black-900);
      }
    }
  }

  .estimation-disclaimer {
    font-size: 10px;
    color: var(--black-900);
  }

  .tariff-availability {
    font-size: 12px;
    color: var(--black-900);
  }

  #content {
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    border: 0;
    padding: 60px;
  }
}

.open-tariff {
  opacity: 1;
  visibility: visible;
}

// Options Left Section

.options-left {
  img {
    max-width: 400px;
    float: right;
  }

  .align-center {
    align-items: center;
  }

  h2 {
    max-width: 500px;
  }
}

.options-right {
  .scaled-image_1 {
    margin-top: 200px;
    transform: scale(1.2);
    max-width: 650px;
  }

  .scaled-image_2 {
    margin-top: 100px;
    max-width: 700px;
  }

  img {
    max-width: 400px;
  }

  .align-center {
    align-items: center;
  }

  h2 {
    max-width: 500px;
  }
}

// Features Section

.features {
  .feature-item {
    box-shadow: var(--shadow-black-100);
    margin: 15px;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      background-color: var(--color-1);
      left: -20px;
      top: 40%;
      transition: all 0.5s ease;
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background-color: var(--color-2);
      right: -30px;
      top: 80%;
      transition: all 0.5s ease;
      opacity: 0;
    }

    &:hover {
      &:before {
        left: 10px;
        opacity: 1;
      }

      &:after {
        right: -15px;
        opacity: 1;
      }

      .icon {
        color: #ffffff;
        font-size: 20px;

        &:before {
          left: 0;
          border-radius: 50%;
          opacity: 1;
        }
      }
    }

    .icon {
      height: 60px;
      width: 60px;
      margin: 0 auto 30px;
      text-align: center;
      font-size: 30px;
      color: var(--main-color);
      position: relative;
      z-index: 1;
      transition: all 0.5s ease;

      &:before {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--main-color);
        transition: all 0.5s ease;
        z-index: -1;
        opacity: 0;
      }

      .fontAwesome {
        line-height: 60px;
      }
    }
  }
}

//  Fun Facts Section

.fun-facts {
  background-color: var(--black-100);

  img {
    max-width: 250px;
    width: 100%;
    box-shadow: var(--shadow-black-100);
    border-radius: 32px;
    animation: bounceTop_01 3s ease infinite;
  }

  .section-title {
    margin-bottom: 20px;

    h2 {
      text-align: left;
    }
  }
}

.fun-facts-text p {
  font-size: 16px;
  line-height: 26px;
  color: var(--black-400);
  margin: 0 0 15px;
  font-weight: 300;
}

.fun-fact-item {
  padding: 30px;
  text-align: center;
  box-shadow: var(--shadow-black-100);
  border-radius: 10px;
  margin-top: 30px;

  h3 {
    font-size: 40px;
    font-weight: 700;
    color: #ffffff;
    margin: 0 0 5px;
  }

  span {
    font-size: 16px;
    text-transform: uppercase;
    font-weight: 300;
    margin: 0;
    line-height: 26px;
    display: block;
    color: #ffffff;
  }
}

.fun-fact-item.style-1 {
  background-color: var(--color-1);
}

.fun-fact-item.style-2 {
  background-color: var(--color-2);
}

.fun-fact-item.style-3 {
  background-color: var(--color-3);
}

.fun-fact-item.style-4 {
  background-color: var(--main-color);
}

// How it works section

.how-it-works-item {
  padding: 30px;
  text-align: center;
  position: relative;
  z-index: 1;

  .step {
    display: inline-block;
    height: 60px;
    width: 60px;
    background-color: var(--main-color);
    font-size: 30px;
    text-align: center;
    line-height: 60px;
    border-radius: 50%;
    color: #ffffff;
    font-weight: 500;
    margin: 0 0 15px;
  }

  h3 {
    margin: 20px 0;
    font-size: 22px;
    font-weight: 500;
    color: var(--black-900);
    text-transform: capitalize;
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    color: var(--black-400);
    line-height: 26px;
  }
}

.how-it-works-item.line-right {
  &:before {
    content: '';
    position: absolute;
    height: 2px;
    width: 100%;
    left: 50%;
    top: 60px;
    background-color: var(--main-color);
    z-index: -1;
  }
}

// FAQ

.faq {
  .container {
    .row {
      .col-lg-12 {
        .section-title {
          h2 {
            text-align: center;
          }
        }
      }
    }
  }

  .tab-body {
    position: relative;
    height: auto;
    overflow: hidden;

    .faq-container {
      width: 100%;
      opacity: 0;
      top: -100%;
      transform: translateY(-10px);
      transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    }

    .faq-container.active-faq {
      margin-left: 0;
      position: absolute;
      display: flex;
      transform: translateY(0px);
      top: 30px;
      opacity: 1;
    }

    .faq-container.inactive-faq {
      display: none;
    }
  }

  .accordion-item {
    margin-bottom: 20px;
    border: 0px;
    background-color: transparent;

    &:first-of-type {
      border-radius: 0px;
    }

    &:last-of-type {
      border-radius: 0px;
    }
  }

  .accordion-header {
    box-shadow: var(--shadow-black-100);
    padding: 10px 30px 2px 5px;
    transition: all 0.5s ease;
    position: relative;

    // &:before {
    //   content: '+';
    //   font-weight: 300;
    //   position: absolute;
    //   height: 30px;
    //   width: 30px;
    //   right: 10px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   font-size: 26px;
    //   text-align: center;
    //   line-height: 30px;
    //   color: var(--main-color);
    //   transition: all 0.5s ease;
    // }

    h3 {
      font-size: 18px;
      font-weight: 500;
      color: var(--black-400);
      margin: 0;
      transition: all 0.5s ease;
    }

    &:not(.collapsed) {
      // background-color: var(--main-color);
      box-shadow: none;

      h3 {
        color: #ffffff;
      }
    }
  }

  .accordion-body {
    padding: 20px 30px;
    background-color: var(--black-100);

    p {
      font-weight: 300;
      font-size: 16px;
      color: var(--black-400);
      line-height: 26px;
      margin: 0;
    }
  }
}

// Tabs FAQ
.faq-navbar-nav {
  background-color: transparent;
  padding: 20px 0;
  transition: all 0.5s ease;
  margin: 0 auto;
  text-align: center;
  cursor: pointer;

  .faq-item {
    padding-left: 40px;
    display: inline-block;

    .faq-link {
      color: var(--black-900);
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      padding: 5px 0;
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background-color: var(--main-color);
        transition: all 0.5s ease;
        transform: scale(0);
      }

      &:hover:before {
        transform: scale(1);
      }
    }

    .faq-link.active-faq {
      &:before {
        transform: scale(1);
      }
    }
  }
}

// Order

.order {
  .no-data-found {
    justify-content: center;
    padding: 20px 30px;
    background-color: var(--black-100);

    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
      margin: 0;
      text-align: center;
      transition: all 0.5s ease;
    }
  }

  .tab-body {
    position: relative;
    height: auto;

    .order-container {
      width: 100%;
      opacity: 0;
      top: -100%;
      transform: translateY(-10px);
      transition: opacity 300ms ease-in-out, transform 300ms ease-in-out;
    }

    .order-container.active-order {
      margin-left: 0;
      // position: absolute;
      // display: flex;
      transform: translateY(0px);
      top: 30px;
      opacity: 1;
    }

    .order-container.inactive-order {
      display: none;
    }
  }

  .accordion-item {
    margin-bottom: 20px;
    border: 0px;
    background-color: transparent;

    &:first-of-type {
      border-radius: 0px;
    }

    &:last-of-type {
      border-radius: 0px;
    }
  }

  .accordion-header {
    box-shadow: var(--shadow-black-100);
    padding: 20px 50px 20px 30px;
    transition: all 0.5s ease;
    position: relative;

    // &:before {
    //   content: '+';
    //   font-weight: 300;
    //   position: absolute;
    //   height: 30px;
    //   width: 30px;
    //   right: 10px;
    //   top: 50%;
    //   transform: translateY(-50%);
    //   font-size: 26px;
    //   text-align: center;
    //   line-height: 30px;
    //   color: var(--main-color);
    //   transition: all 0.5s ease;
    // }

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      color: var(--black-400);
      margin: 0;
      transition: all 0.5s ease;
    }

    p {
      font-size: 14px;
      font-weight: 500;
      color: var(--black-400);
      margin: 0;
      transition: all 0.5s ease;
    }

    &:not(.collapsed) {
      background-color: var(--black-000);

      &:before {
        content: '-';
        color: var(--main-color);
      }

      p {
        color: var(--black-400);
      }

      h3 {
        color: var(--black-400);
      }
    }
  }

  .order-summary {
    .status-completed {
      padding: 3px;
      background-color: rgba(82, 179, 2, 0.2);
      color: var(--main-color);
      border-radius: 5px;
      max-width: 100px;
      text-align: center;
      text-transform: capitalize;
    }

    .status-scheduled_accepted {
      padding: 3px;
      background-color: rgba(52, 151, 253, 0.2);
      color: #3497fd;
      border-radius: 5px;
      max-width: 100px;
      text-align: center;
      text-transform: capitalize;
    }

    .status-scheduled {
      padding: 3px;
      background-color: rgba(52, 151, 253, 0.2);
      color: #3497fd;
      border-radius: 5px;
      max-width: 100px;
      text-align: center;
      text-transform: capitalize;
    }

    .status-client_canceled {
      padding: 3px;
      background-color: rgba(255, 48, 48, 0.2);
      color: #ff3030;
      border-radius: 5px;
      max-width: 100px;
      text-align: center;
      text-transform: capitalize;
    }

    .status-driver_canceled {
      padding: 3px;
      background-color: rgba(255, 48, 48, 0.2);
      color: #ff3030;
      border-radius: 5px;
      max-width: 135px;
      text-align: center;
      text-transform: capitalize;
    }
  }

  .driver-card {
    .avatar {
      width: 50px;
      height: 50px;
      border-radius: 50%;
      box-shadow: var(--shadow-black-100);
    }

    .logo-card {
      height: 40px;
    }

    h3 {
      font-size: 18px;
      font-weight: 700;
      line-height: 20px;
      color: var(--black-400);
      margin: 0;
      transition: all 0.5s ease;
      padding-left: 15px;
    }

    p {
      font-size: 16px;
      color: var(---black-100);
      margin-bottom: 0px;
    }
  }

  .accordion-body {
    padding: 20px 30px;
    background-color: var(--black-100);

    p {
      font-weight: 300;
      font-size: 16px;
      color: var(--black-400);
      line-height: 26px;
      margin: 0;
    }
  }

  .journey-icon {
    width: 40px;
    color: var(--dark-to-main-color);
    font-size: 16px;
    text-align: center;

    .fontAwesome {
      line-height: 25px;
    }
  }
}

// Tabs Order
.order-navbar-nav {
  background-color: transparent;
  padding: 20px 0;
  transition: all 0.5s ease;
  text-align: left;
  cursor: pointer;

  .order-item {
    padding-right: 40px;
    display: inline-block;

    .order-link {
      color: var(--black-900);
      text-transform: capitalize;
      font-size: 16px;
      font-weight: 400;
      padding: 5px 0;
      position: relative;
      text-decoration: none;

      &:before {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        right: 0;
        height: 1px;
        background-color: var(--main-color);
        transition: all 0.5s ease;
        transform: scale(0);
      }

      &:hover:before {
        transform: scale(1);
      }
    }

    .order-link.active-order {
      &:before {
        transform: scale(1);
      }
    }
  }
}

// Get In touch

.contact {
  background-color: var(--black-100);

  .section-title {
    text-align: center;
  }
}

.contact-info {
  h3 {
    font-size: 22px;
    color: var(--black-900);
    font-weight: 500;
    margin: 0 0 40px;
  }
}

.contact-info-item {
  position: relative;
  padding-left: 55px;
  margin-bottom: 30px;

  .icon {
    position: absolute;
    top: 0;
    left: 0;
    border-radius: 50%;
    font-size: 16px;
    color: var(--main-color);
    border: 1px solid var(--main-color);
    text-align: center;
    line-height: 38px;
    padding: 10px;
  }

  h4 {
    font-size: 18px;
    font-weight: 400;
    margin: 0 0 10px;
    color: var(--black-900);
  }

  p {
    font-size: 16px;
    font-weight: 300;
    margin: 0;
    line-height: 26px;
    color: var(--black-400);
  }
}

.contact-form {
  .form-group {
    margin-bottom: 25px;
  }

  .form-control {
    height: 52px;
    border: 1px solid transparent;
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    padding: 0 24px;
    color: var(--black-900);
    background-color: var(--black-000);
    transition: all 0.5s ease;

    &:focus {
      border-color: var(--main-color);
    }
  }

  textarea.form-control {
    height: 140px;
    padding-top: 12px;
    resize: none;
  }
}

// Verify Email

.verify {
  text-align: center;

  .section-title {
    p {
      text-transform: none;
    }

    span {
      text-transform: none;
    }
  }
}

// Locations

.locations {
  .section-title {
    p {
      text-transform: none;
    }

    span {
      text-transform: none;
    }
  }

  ul {
    margin: 0;
    padding-left: 0;

    li {
      &:not(:last-child) {
        margin-bottom: 10px;
      }

      a {
        font-size: 16px;
        font-weight: 300;
        display: block;
        text-transform: capitalize;
        transition: all 0.5s ease;
        text-decoration: none;
        color: var(--main-color);

        p {
          font-size: 16px;
          font-weight: 300;
          line-height: 26px;
          margin: 0;
        }

        &:hover {
          padding-left: 5px;
        }
      }
    }
  }
}

// #GoGreenGoVert

.gogreen {
  background-image: url('/public/images/gogreen.png');
  background-size: contain;
  background-position: bottom;
  background-repeat: no-repeat;
}

.gogreen-small {
  display: none;
}

// DownloadDriverApp

.download-driver {
  background-image: url('/public/images/download_driver.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.download-driver-meta {
  background-color: var(--main-to-dark-color);
}

.download-driver-small {
  display: none;
}

// Footer

.footer {
  background-color: var(--main-color);
  padding: 50px 0;

  .footer-col {
    h3 {
      font-size: 18px;
      font-weight: 500;
      color: #ffffff;
      margin: 0 0 25px;
      text-transform: capitalize;
    }

    p {
      font-size: 16px;
      color: rgba(255, 255, 255, 0.9);
      font-weight: 300;
      line-height: 26px;
      margin: 0;
    }

    ul {
      margin: 0;
      padding-left: 0;

      li {
        &:not(:last-child) {
          margin-bottom: 10px;
        }

        a {
          font-size: 16px;
          color: rgba(255, 255, 255, 0.9);
          font-weight: 300;
          display: block;
          text-transform: capitalize;
          transition: all 0.5s ease;
          text-decoration: none;

          &:hover {
            padding-left: 5px;
          }
        }
      }
    }
  }

  .copyright-text {
    padding: 20px 0;
    text-align: center;
    font-size: 16px;
    color: rgba(255, 255, 255, 0.9);
    font-weight: 300;
    margin: 50px 0 0;
    border-top: 1px solid rgba(255, 255, 255, 0.2);
  }
}

// =================================
// Responsive
// =================================

@media (max-width: 1200px) {
  // .home-img {
  //   display: none;
  // }

 

  .options-right {
    .scaled-image_1 {
      margin-top: 250px;
      transform: scale(1.5);
      max-width: 400px;
    }

    .scaled-image_2 {
      margin-top: 170px;
      max-width: 700px;
    }
  }
}

@media (max-width: 1080px){
  .circle-ripple {
    margin-top: -237px;
    margin-left: 378px;
    scale: 0.2
  }
}

@media (max-width: 991px) {

  .navbar {
    .navbar-toggler {
      background-color: var(--dark-to-main-color);
      box-shadow: var(--shadow-black-300);
      height: 34px;
      width: 34px;
      padding: 0;
      font-size: 17px;
      color: #ffffff;
    }

    .navbar-nav {
      background-color: var(--dark-to-main-color);
      box-shadow: var(--shadow-black-300);
    }

    .nav-item {
      margin: 0;
      padding: 5px 15px;
    }
  }

  .navbar.navbar-shrink {
    .navbar-toggler {
      background-color: var(--main-color);
    }

    .navbar-nav {
      background-color: var(--main-color);
    }
  }

  .home-img {
    .circle {
      height: 320px;
      width: 320px;
      // top: 15%;
      // left: 120px;
    }

    .home-background {
      top: 30px;
      left: 20px;
    }

    h1 {
      font-size: 40px;
    }
  }

  .options-right {
    .scaled-image_1 {
      margin-top: 200px;
      transform: scale(1.2);
      max-width: 690px;
      align-self: center;
    }

    .scaled-image_2 {
      margin-top: 0px;
    }
  }

  .how-it-works-item {
    box-shadow: var(--shadow-black-100);
    border-radius: 10px;
    margin-bottom: 30px;
  }

  .how-it-works-item.line-right {
    &:before {
      content: '';
      position: relative;
      height: 2px;
      width: 100%;
      left: 50%;
      top: 60px;
      background-color: var(--main-color);
      z-index: -1;
    }
  }

  .footer-col {
    margin-bottom: 30px;
  }

  // #GoGreenGoVert

  .gogreen {
    // background-image: url();
    background-size: contain;
    background-position: bottom;
    background-repeat: no-repeat;
    display: none;
  }

  .gogreen.section-padding {
    padding-bottom: 0;
  }

  // .gogreen-small {
  //   display: block;
  //   background-image: url('/public/images/gogreen.png');
  //   background-size: contain;
  //   background-position: bottom;
  //   background-repeat: no-repeat;
  //   height: 400px;
  //   width: 100%;
  // }
}

@media (max-width: 767px) {
  .home-text {
    margin-bottom: 80px;
  }


  .contact-form {
    margin-top: 20px;
  }

  .section-title {
    h2 {
      font-size: 35px;
    }
  }

  .fun-facts {
    .section-title {
      h2 {
        text-align: center;
      }
    }
  }
}

@media (max-width: 575px) {
  .home-img {
    .circle-car {
      height: 280px;
      width: 280px;
      img {
        transform: translate(100%, -50%);
      }
    }
  }
  .circle-ripple {
    margin-top: -201px;
    margin-left: 311px;
    scale: 0.2
  }

  .home-text {
    h1 {
      font-size: 30px;
    }

    p {
      font-size: 16px;
    }
  }

  .section-title {
    h2 {
      font-size: 30px;
      line-height: 35px;
    }

    p {
      font-size: 20px;
    }
  }

  .options-left {
    .scaled-image_1 {
      max-width: 200px;
    }

    .scaled-image_2 {
      max-width: 200px;
    }

    img {
      max-width: 200px;
      float: none;
    }
  }

  .options-right {
    .scaled-image_1 {
      max-width: 320px;
      margin: 0;
    }

    .scaled-image_2 {
      max-width: 380px;
      margin: 0;
    }

    img {
      max-width: 200px;
    }

    .align-center {
      align-items: center;
    }

    h2 {
      max-width: 500px;
    }
  }

  .tariff-popup {
    padding: 0;

    .tariff-container {
      border-radius: 0px;
    }

    .tariff-popup-close {
      top: 30px;
      right: 30px;
    }
  }
}

.btn-1 {
  background-color: #ffffff;
  padding: 12px 30px;
  border: none;
  border-radius: 10px;
  color: var(--main-color);
  font-size: 16px;
  text-transform: capitalize;
  transition: all 0.5s ease;
  box-shadow: var(--shadow-black-300);
  font-weight: 500;

  &:focus {
    box-shadow: var(--shadow-black-300);
  }

  &:hover {
    color: #ffffff;
    background-color: var(--main-color);
  }
}

.btn-2 {
  background-color: var(--main-color);
  padding: 12px 30px;
  border: none;
  border-radius: 10px;
  color: #ffffff;
  font-size: 16px;
  text-transform: capitalize;
  transition: all 0.5s ease;
  box-shadow: var(--shadow-black-300);
  font-weight: 500;

  &:focus {
    box-shadow: var(--shadow-black-300);
  }

  &:hover {
    color: #000000;
    background-color: #ffffff;
  }
}

.mainGrid {
  // justify-content: space-between;

  @media (max-width: 991px) {
    justify-content: space-between !important;
  }

  @media (max-width: 767px) {
    justify-content: start !important;
  }

  @media (max-width: 1270px) {
    flex-wrap: wrap;
  }

  ._main {
    @media (max-width: 1270px) {
      max-width: 50%;
    }

    @media (max-width: 990px) {
      max-width: 100%;
    }
  }

  .mainBoxTrip {
    margin-bottom: 20px;

    @media (max-width: 991px) {
      flex: 0 0 49%;
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }

  .mainBox {
    flex: 0 0 18%;
    margin-bottom: 20px;
    margin-left: 10px;

    @media (max-width: 991px) {
      flex: 0 0 49%;
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }

  .mainInfoBox {
    flex: 0 0 22%;
    margin-bottom: 20px;
    margin-left: 10px;

    @media (max-width: 991px) {
      flex: 0 0 49%;
    }

    @media (max-width: 767px) {
      flex: 0 0 100%;
    }
  }
}

.scroll-post {
  overflow-y: scroll;
  height: 100%;
  margin-top: 20px;
  max-height: 300px;
}

.order-request {
  .order-request-form {
    form {
      .row {
        .col-lg-8 {
          position: relative;

          p {
            color: #000;
            font-size: 16px;
          }

          div {
            &:nth-child(2) {
              overflow-y: scroll;
              width: 100%;
            }
          }
        }
      }
    }
  }
}

html,
body,
#__next {
  height: 100%;
}

.dark {
  .chakra-card {
    box-shadow: 0 5px 15px rgb(82 179 3 / 16%);
  }
}

.services {
  min-height: 100vh;
  padding: 150px 0;
  background-color: var(--main-to-dark-color);
  background-image: url('/images/background_services.jpg');
  background-size: cover;
  position: relative;
  z-index: 1;
  overflow-x: hidden;
}

.download-client {
  background-image: url('/images/download_client.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

// DownloadDriverApp

.download-driver {
  background-image: url('/images/download_driver.jpg');
  background-size: cover;
  background-position: bottom;
  background-repeat: no-repeat;
  min-height: 100vh;
}

.download-driver-meta {
  background-color: var(--main-to-dark-color);
}

.download-driver-small {
  display: none;
}

.features {
  .feature-item {
    box-shadow: var(--shadow-black-100);
    margin: 15px;
    padding: 30px;
    border-radius: 10px;
    text-align: center;
    position: relative;
    z-index: 1;
    overflow: hidden;
    height: 350px;

    &:before {
      content: '';
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      height: 20px;
      width: 20px;
      background-color: var(--color-1);
      left: -20px;
      top: 40%;
      transition: all 0.5s ease;
      opacity: 0;
    }

    &:after {
      content: '';
      position: absolute;
      z-index: -1;
      border-radius: 50%;
      height: 30px;
      width: 30px;
      background-color: var(--color-2);
      right: -30px;
      top: 80%;
      transition: all 0.5s ease;
      opacity: 0;
    }

    &:hover {
      &:before {
        left: 10px;
        opacity: 1;
      }

      &:after {
        right: -15px;
        opacity: 1;
      }

      .icon {
        color: #ffffff;
        font-size: 20px;

        &:before {
          left: 0;
          border-radius: 50%;
          opacity: 1;
        }
      }
    }

    .icon {
      height: 60px;
      width: 60px;
      margin: 0 auto 30px;
      text-align: center;
      font-size: 30px;
      color: var(--main-color);
      position: relative;
      z-index: 1;
      transition: all 0.5s ease;

      &:before {
        content: '';
        position: absolute;
        left: 100%;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: var(--main-color);
        transition: all 0.5s ease;
        z-index: -1;
        opacity: 0;
      }

      .fontAwesome {
        line-height: 60px;
      }
    }
  }
}

// Services Icons
.services-vert {
  background-image: url('/images/standard.png');
  height: 230px;
  width: 100%;
  background-size: 230px;
  background-repeat: no-repeat;
  background-position: center;
}

.services-vert-prime {
  background-image: url('/images/vert_exec.png');
  height: 230px;
  width: 100%;
  background-size: 230px;
  background-repeat: no-repeat;
  background-position: center;
}

.services-vert-elite {
  background-image: url('/images/vert_plus.png');
  height: 230px;
  width: 100%;
  background-size: 230px;
  background-repeat: no-repeat;
  background-position: center;
}

.services-description {
  padding-left: 30px;
  padding-right: 30px;
  font-size: 17px;
  margin: 0 0 20px;
  color: var(--black-900);
  font-weight: 500;
  text-transform: capitalize;
}

.slick-slide {
  margin: 0 -10px;
}

/* the parent */
// .slick-list {
//   margin: 0 -10px;
// }
