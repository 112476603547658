.App {
    text-align: center;
}

.App-logo {
    height: 40vmin;
    pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}

.App-header {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.pagination {
    margin: 15px auto;
    display: flex;
    list-style: none;
    outline: none;
}

.pagination>.active>a {
    background-color: 'grey';
    border-color: 'grey';
    color: #fff;
}

.pagination>li>a {
    border: 1px solid 'grey';
    padding: 5px 10px;
    outline: none;
    cursor: pointer;
}

/* .pagination>.active>a,
.pagination>.active>span,
.pagination>.active>a:hover,
.pagination>.active>span:hover,
.pagination>.active>a:focus,
.pagination>.active>span:focus {
    background-color: 'grey';
    border-color: 'grey';
    outline: none;
} */
/* 
.pagination>li:first-child>a,
.pagination>li:first-child>span,
.pagination>li:last-child>a,
.pagination>li:last-child>span {
    border-radius: unset
} */

.tooltips {
    position: relative;
    display: inline-block;
  }
  
.tooltips .tooltipstext {
    width: 70px;
    color: #fff;
    text-align: center;
    border-radius: 6px;
    padding: 5px 0px;
    position: absolute;
    z-index: 1;
    bottom: 150%;
    left: 50%;
    margin-left: -35px;
  }
  
  .ripple1, .ripple2, .ripple3 {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    transform-origin: center;
    border-radius: 50%;
    width: 0;
    height: 0;
    border: 0.3em solid white;
    opacity: 1;

    /* Fix Safari Flashing */
    will-change: transform, opacity;
    backface-visibility: hidden;
}

.ripple1 {
    animation: expand 5s linear infinite;
}
.ripple2 {
    animation: expand 5s linear infinite 1.5s;
}
.ripple3 {
    animation: expand 5s linear infinite 3s;
}

/* Smooth expansion and fade out */
@keyframes expand {
    0% {
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        width: 10em;
        height: 10em;
        opacity: 0;
    }
}

/* Safari-specific keyframe fix */
@-webkit-keyframes expand {
    0% {
        width: 0;
        height: 0;
        opacity: 1;
    }
    100% {
        width: 10em;
        height: 10em;
        opacity: 0;
    }
}
  
.marker {
    transition: all 5s ease; /* Adjust the duration and easing function as needed */
}

  